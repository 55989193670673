import { generateGameToken, addPoints, removePoints } from '../services/api';
import { toast } from 'react-toastify';
import values from '../data/values';

function calculePoints(items) {
    let totalPoints = 0;

    items.forEach(item => {
        if (values.hasOwnProperty(item)) {
            totalPoints += values[item];
        } else {
            totalPoints += 1;
        }
    });

    return totalPoints;
}

export function saveGameToken(squares, setGameToken) {
    const completedSquares = squares.filter(square => square.value !== '' && square.playerName !== '');

    if (completedSquares.length === 2) {
        generateGameToken()
            .then(data => {
                setGameToken(data.token);
            })
            .catch(error => {
                console.error('Failed to generate game token:', error);
            });
    } else if (completedSquares.length < 2) {
        setGameToken(null);
    }
}

export function savePoints(gameToken, result, difficulty, league, streak, items) {
    const points = calculePoints(items);

    if (gameToken && result === 'X') {
        addPoints(gameToken, points, difficulty, league, streak)
            .then(data => {
                if (data.streakBonus === 0) {
                    toast.success(
                        `+ ${data.pointsAdded} POINTS `
                    );
                    return;
                }
                toast.success(
                    `+ ${data.pointsAdded - data.streakBonus} + 🔥 ${data.streakBonus} POINTS`
                );
            })
            .catch(error => {
                console.error('Failed to add points:', error);
                toast.error('Failed to add points');
            });
    } else if (gameToken && result === 'O') {
        removePoints(gameToken, difficulty, league)
            .then(data => {
                toast.error(
                    `- ${data.pointsRemoved} POINTS `
                );
            })
            .catch(error => {
                console.error('Failed to remove points:', error);
                toast.error('Failed to remove points');
            });
    }
}
