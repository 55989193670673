import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import './AuthRoute.css';

function AuthRoute({ children }) {
    let { user, isUserLoading } = useAuth();
    let navigate = useNavigate();

    useEffect(() => {
        if (!isUserLoading && user === null) {
            toast.error("Debes iniciar sesión para acceder a esta página.", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            navigate("/");
        }
    }, [user, navigate, isUserLoading]);

    if (isUserLoading) {
        return (
            <div className="authroute-container">
                <div className="authroute-content">
                    <div className="spinner"></div>
                    <p>Cargando...</p>
                </div>
            </div>
        );
    }

    return children;
}

export default AuthRoute;