import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import latinize from 'latinize';
import TimeLimitContext from '../../context/TimeLimitContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { generateCustomArray } from '../../helpers/generateCustomArray';
import { winningPositions } from '../../helpers/winningPositions';
import { checkPlayer } from '../../helpers/checkPlayer';
import { saveGameToken, savePoints } from '../../helpers/botPoints';
import { changeDifficulty, saveStreak } from '../../helpers/streakManagement';
import { FlameIcon } from '../../helpers/animations/flame';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../../components/Navbar/Navbar';
import Title from '../../components/Title/Title';
import Board from '../../components/Board/Board';
import ScoreBoard from '../../components/ScoreBoard/ScoreBoard';
import NextButton from '../../components/NextButton/NextButton';
import PlayerSelected from '../../components/PlayerSelected/PlayerSelected';
import CountdownTimer from '../../components/CountdownTimer/CountdownTimer';
import './SoloBotPage.css';

const backendURL = 'https://footy-tictactoe-gviwhaysya-uc.a.run.app';

const SoloBotPage = () => {
  const navigate = useNavigate();
  const [turn, setTurn] = useState('X');
  const [squares, setSquares] = useState(Array(9).fill({ value: '', playerName: '' }));
  const [winningSquares, setWinningSquares] = useState([]);
  const [score, setScore] = useState({
    X: 0,
    O: 0,
  });
  const [streak, setStreak] = useState(0);
  const [difficulty, setDifficulty] = useState('medium');
  const [league, setLeague] = useState('normal');
  const [items, setItems] = useState(Array(6).fill({}));
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const { timeLimit } = useContext(TimeLimitContext);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit);
  const [gameToken, setGameToken] = useState(null);
  const hasAnyData = squares.some(square => square.value !== '' || square.playerName !== '');
  const location = useLocation();
  const itemMaping = useMemo(() => ({
    0: [items[0], items[3]],
    1: [items[1], items[3]],
    2: [items[2], items[3]],
    3: [items[0], items[4]],
    4: [items[1], items[4]],
    5: [items[2], items[4]],
    6: [items[0], items[5]],
    7: [items[1], items[5]],
    8: [items[2], items[5]],
  }), [items]);

  useEffect(() => {
    if (location.state && location.state.mode) {
      if (location.state.mode === 'normal') {
        setLeague('normal');
        setItems(generateCustomArray());
      } else {
        return;
      }
    }
  }, [location.state]);

  useEffect(() => {
    setTimeRemaining(timeLimit);

    const timer = setInterval(() => {
      setTimeRemaining((prevTimeRemaining) => {
        if (prevTimeRemaining === 0) {
          handleSkipTurn();
          setSelectedPlayer(null);
          return timeLimit;
        }
        return prevTimeRemaining - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLimit, turn]);

  useEffect(() => {
    saveGameToken(squares, setGameToken);
  }, [squares]);

  const handleSkipTurn = () => {
    setTurn((prevTurn) => (prevTurn === 'X' ? 'O' : 'X'));
  }

  const handleChangeItems = useCallback(() => {
    if (league === 'normal') {
      setItems(generateCustomArray());
    }
  }, [league]);

  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
  }

  const handleClick = (square) => {
    if (!selectedPlayer) {
      toast.error('Select a player first', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      return;
    }

    const [item1, item2] = itemMaping[square];
    const playedInItem1 = checkPlayer(selectedPlayer, item1);
    const playedInItem2 = checkPlayer(selectedPlayer, item2);

    if (playedInItem1 && playedInItem2) {
      let newSquares = [...squares];
      newSquares.splice(square, 1, { value: turn, playerName: selectedPlayer.nombre })
      checkForWinner(newSquares);
      setSquares(newSquares);
      setSelectedPlayer(null);
    } else {
      toast.warn('The player does not meet the requirements. 😔You lost the turn.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      handleSkipTurn();
      setSelectedPlayer(null);
    }
  }

  const reset = useCallback(() => {
    const newTurn = turn === 'X' ? 'O' : 'X';
    setSquares(Array(9).fill({ value: '', playerName: '' }));
    setTurn(newTurn);
    setWinningSquares([]);
    setTimeRemaining(timeLimit);
    handleChangeItems();
  }, [turn, timeLimit, handleChangeItems]);

  const endGame = useCallback((result, winningPositions) => {
    setTurn(null);
    if (result != null) {
      setScore({
        ...score,
        [result]: score[result] + 1,
      })
      saveStreak(streak, setStreak, result);
    }

    setWinningSquares(winningPositions);
    setTimeout(reset, 3000);
    savePoints(gameToken, result, difficulty, league, streak, items);
  }, [score, reset, gameToken, difficulty, league, streak, items]);

  const checkForWinner = useCallback((newSquares) => {
    for (let i = 0; i < winningPositions.length; i++) {
      const [a, b, c] = winningPositions[i];
      if (newSquares[a] && newSquares[a].value !== '' && newSquares[a].value === newSquares[b].value && newSquares[a].value === newSquares[c].value) {
        endGame(newSquares[a].value, winningPositions[i])
        return
      }
    }

    if (!newSquares.some(square => square.value === '')) {
      endGame(null, Array.from(Array(10).keys()));
      return;
    }
    handleSkipTurn();
  }, [endGame]);

  const handleClickTitle = () => {
    navigate('/')
  }

  useEffect(() => {
    let timeOutId;
    const botLogic = () => {
      const fetchBotPlayers = async (item1, item2, league) => {
        try {
          const customLatinize = (text) => {
            return text.split(/(ñ)/gi).map(part => {
              // Si es "ñ", mantenlo igual, si no, aplica latinize
              return part.toLowerCase() === 'ñ' ? part : latinize(part);
            }).join('');
          };

          const response = await fetch(`${backendURL}/players/searchByCriteria?item1=${customLatinize(item1.toLowerCase())}&item2=${customLatinize(item2.toLowerCase())}&league=${league}`);
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching bot players:', error);
          return [];
        }
      };

      const availableSquares = squares.reduce((acc, square, idx) => {
        if (square.value === '') {
          acc.push(idx);
        }
        return acc;
      }, []);

      if (availableSquares.length === 0) {
        endGame(null, Array.from(Array(10).keys()));
        return;
      }

      const delay = Math.random() * (10000 - 3000) + 3000; // Tiempo aleatorio entre 3 y 10 segundos
      timeOutId = setTimeout(async () => {
        let chosenSquare;

        if (difficulty === 'medium' || difficulty === 'hard') {
          if (squares[4].value === '') {
            chosenSquare = 4;
          }
        }

        if (!chosenSquare && (difficulty === 'medium' || difficulty === 'hard')) {
          for (let [a, b, c] of winningPositions) {
            if (squares[a].value === 'O' && squares[a].value === squares[b].value && squares[c].value === '') {
              chosenSquare = c;
              break;
            } else if (squares[a].value === 'O' && squares[a].value === squares[c].value && squares[b].value === '') {
              chosenSquare = b;
              break;
            } else if (squares[b].value === 'O' && squares[b].value === squares[c].value && squares[a].value === '') {
              chosenSquare = a;
              break;
            }
          }
        }

        if (!chosenSquare && difficulty === 'hard') {
          for (let [a, b, c] of winningPositions) {
            if (squares[a].value === 'X' && squares[a].value === squares[b].value && squares[c].value === '') {
              chosenSquare = c;
              break;
            } else if (squares[a].value === 'X' && squares[a].value === squares[c].value && squares[b].value === '') {
              chosenSquare = b;
              break;
            } else if (squares[b].value === 'X' && squares[b].value === squares[c].value && squares[a].value === '') {
              chosenSquare = a;
              break;
            }
          }
        }

        if (chosenSquare === undefined) {
          chosenSquare = availableSquares[Math.floor(Math.random() * availableSquares.length)];
        }

        const [item1, item2] = itemMaping[chosenSquare];
        const botPlayers = await fetchBotPlayers(item1, item2, league);

        if (botPlayers.length > 0) {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();

          const playersInAgeRange = botPlayers.filter(player => {
            const birthYear = parseInt(player.fecha_nacimiento.split('.')[2]);
            const age = currentYear - birthYear;
            return age >= 20 && age <= 36;
          });

          let chosenPlayers = playersInAgeRange.length > 0 ? playersInAgeRange : botPlayers;

          const botPlayer = chosenPlayers[Math.floor(Math.random() * chosenPlayers.length)];

          let newSquares = [...squares];
          newSquares.splice(chosenSquare, 1, { value: 'O', playerName: botPlayer.nombre });
          checkForWinner(newSquares);
          setSquares(newSquares);
          return;
        }

        handleSkipTurn();
      }, delay);
    }

    if (turn === 'O') {
      botLogic();
    }

    return () => clearTimeout(timeOutId);
  }, [turn, squares, endGame, itemMaping, checkForWinner, difficulty, league]);


  return (
    <div className="container">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Navbar />
      <Title onClick={handleClickTitle} />
      <ScoreBoard scoreO={score.O} scoreX={score.X} />
      {selectedPlayer && <PlayerSelected player={selectedPlayer} />}
      <NextButton onClick={reset} changeTurn={handleSkipTurn} turn={turn} mode='bot' setStreak={setStreak} />
      {timeRemaining !== null && <CountdownTimer timeRemaining={timeRemaining} />}
      <div className="difficulty-streak-wrapper">
        <div className="difficulty-selector-wrapper">
          Choose Bot Difficulty:
          <select
            className="difficulty-selector"
            value={difficulty}
            onChange={e => changeDifficulty(e.target.value, setDifficulty, streak, setStreak)}
            disabled={hasAnyData}
          >
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
          </select>
        </div>
        <FlameIcon streak={streak} />
      </div>
      <Board winningSquares={winningSquares} turn={turn} squares={squares} onClick={handleClick} clubSquares={items} handlePlayerSelect={handlePlayerSelect} player={turn} playerFootball={selectedPlayer} mode='bot' league={league} />
    </div>
  );
}

export default SoloBotPage;