import { toast } from 'react-toastify';
const API_URL = 'https://footy-tictactoe-gviwhaysya-uc.a.run.app';
let tokenP = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

async function fetchWithToken(endpoint, options = {}) {
    let token = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

    if (!token) {
        toast.error('No token found. Please log in again.');
        throw new Error('No token found.');
    }

    const fetchData = async () => {
        let response;

        try {
            response = await fetch(`${API_URL}${endpoint}`, {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (response.status === 401) { // Unauthorized, token might be expired
                try {
                    token = await refreshToken();
                    // Retry the original request with the new token
                    response = await fetch(`${API_URL}${endpoint}`, {
                        ...options,
                        headers: {
                            ...options.headers,
                            'Authorization': `Bearer ${token}`
                        },
                        credentials: 'include'
                    });
                } catch (refreshError) {
                    console.error('Failed to refresh token:', refreshError);
                    toast.error('Sesión expirada. Por favor inicia sesión nuevamente.');
                    throw new Error('Failed to refresh token.');
                }
            }
            

            if (!response.ok) {
                const errorMessage = await response.text(); // Get error message from response
                throw new Error(errorMessage || 'Error en la solicitud');
            }

            // Check if the response content type is JSON
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                return response.json();
            } else {
                throw new Error('Invalid response format');
            }

        } catch (error) {
            console.error('Error in fetchWithToken:', error);
            throw error;
        }
    };

    return fetchData();
}

async function fetchWithTokenPlayer(endpoint, options = {}) {
    let token = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

    if (!token) {
        toast.error('No token found. Please log in again.');
        return; // Salir de la función si no hay token
    }

    try {
        const response = await fetch(`${API_URL}${endpoint}`, {
            ...options,
            headers: {
                ...options.headers,
                'Authorization': `Bearer ${token}`
            },
            credentials: 'include'
        });

        if (response.status === 401) { // Unauthorized, token might be expired
            token = await refreshToken();
            // Retry the original request with the new token
            return fetch(`${API_URL}${endpoint}`, {
                ...options,
                headers: {
                    ...options.headers,
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });
        }

        // Verificar que la respuesta es válida
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error in the request');
        }

        return response; // Devolver el objeto Response directamente

    } catch (error) {
        console.error('Fetch error:', error);
        throw error; // Re-lanzar el error para manejarlo en el lugar adecuado
    }
}

async function fetchNoUser(endpoint, options = {}) {
    const response = await fetch(`${API_URL}${endpoint}`, {
        ...options,
        credentials: 'include'
    });

    const data = await response.json();

    if (!response.ok) {

        const errorMessage = data.message || 'Error en la solicitud';
        throw new Error(errorMessage);
    }

    return data;
}

async function refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) {
        toast.error('No refresh token found. Please log in again.');
    }

    const response = await fetch(`${API_URL}/auth/refreshToken`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${refreshToken}`
        }
    });

    if (!response.ok) {
        throw new Error('Failed to refresh token');
    }

    const data = await response.json();
    localStorage.setItem('authUserData', JSON.stringify({ token: data.token }));
    return data.token;
}


// Usuarios
export const loginUser = (username, password) => {
    return fetchNoUser('/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    });
}

export const registerUser = (userData) => {
    return fetchNoUser('/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    });
}

export const registerUserWithoutConfirmation = (userData) => {
    return fetchNoUser('/auth/register-without-confirmation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
    });
}

export const confirmUser = (confirmationCode) => {
    return fetchNoUser('/auth/confirm-registration', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ confirmationCode })
    });
}

export const logoutUser = () => {
    return fetchNoUser('/auth/logout', {
        method: 'POST'
    });
}

export const getUser = () => {
    return fetchWithToken('/user', {
        method: 'GET'
    });
}

export const getAllUsers = () => {
    return fetchNoUser('/user/all', {
        method: 'GET'
    });
}

// Game

export const generateGameToken = () => {
    const token = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

    if (!token) {
        toast.error('No token found. Please log in again.');
    }

    return fetchWithToken('/game/generateToken', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
}

export const addPoints = (token, points, difficulty, league, streak) => {

    if (!token) {
        toast.error('No token found. Please log in again.');
    }

    return fetchWithToken('/game/addPoints', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenP}`
        },
        body: JSON.stringify({ token, points, difficulty, league, streak })
    });
}

export const removePoints = (token, difficulty, league) => {
    const tokenP = localStorage.getItem('authUserData') ? JSON.parse(localStorage.getItem('authUserData')).token : null;

    if (!token) {
        toast.error('No token found. Please log in again.');
    }

    return fetchWithToken('/game/removePoints', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokenP}`
        },
        body: JSON.stringify({ token, difficulty, league })
    });
}

// Players

export const addPlayer = async (playerData) => {
    try {
        const response = await fetchWithTokenPlayer('/players', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(playerData)
        });

        // Verifica si response tiene la función json
        if (typeof response.json !== 'function') {
            throw new Error('La respuesta no es un objeto Response válido.');
        }

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al agregar el jugador');
        }

        return responseData; // Devuelve el cuerpo de la respuesta si todo está bien
    } catch (error) {
        console.error('Error al agregar el jugador:', error);
        throw error;
    }
};

export const addPlayerArg = async (playerData) => {
    try {
        const response = await fetchWithTokenPlayer('/players/arg', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(playerData)
        });

        // Verifica si response tiene la función json
        if (typeof response.json !== 'function') {
            throw new Error('La respuesta no es un objeto Response válido.');
        }

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al agregar el jugador');
        }

        return responseData; // Devuelve el cuerpo de la respuesta si todo está bien
    } catch (error) {
        console.error('Error al agregar el jugador:', error);
        throw error;
    }
}

export const updatePlayer = async (playerData) => {
    try {
        const response = await fetchWithTokenPlayer(`/players/${playerData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(playerData),
        });

        // Verifica si response tiene la función json
        if (typeof response.json !== 'function') {
            throw new Error('La respuesta no es un objeto Response válido.');
        }

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al actualizar el jugador');
        }

        return responseData; // Devuelve el cuerpo de la respuesta si todo está bien
    } catch (error) {
        console.error('Error al actualizar el jugador:', error);
        throw error;
    }
}

export const updatePlayerArg = async (playerData) => {
    try {
        const response = await fetchWithTokenPlayer(`/players/arg/${playerData._id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(playerData),
        });

        // Verifica si response tiene la función json
        if (typeof response.json !== 'function') {
            console.log(response.json);
            
            throw new Error('La respuesta no es un objeto Response válido.');
        }

        const responseData = await response.json();

        if (!response.ok) {
            throw new Error(responseData.error || 'Error al actualizar el jugador');
        }

        return responseData; // Devuelve el cuerpo de la respuesta si todo está bien
    } catch (error) {
        console.error('Error al actualizar el jugador:', error);
        throw error;
    }
};

export const deletePlayer = (playerId) => {
    return fetchWithTokenPlayer(`/players/${playerId}`, {
        method: 'DELETE'
    });
}

export const deletePlayerArg = (playerId) => {
    return fetchWithTokenPlayer(`/players/arg/${playerId}`, {
        method: 'DELETE'
    });
}



